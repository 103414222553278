<template>
  <div class="d-flex flex-column">
    <div class="header-top">
      <h2 class="view-title">History</h2>
      <v-text-field
        label="Search"
        v-model="search.search"
        class="search"
        prepend-icon="mdi-magnify"
        clearable
        @click:clear="clearSearch"
      ></v-text-field>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      sort-by="id"
      :sort-desc="sort_desc"
      :footer-props="{ 'items-per-page-options': items_per_page }"
      :options.sync="options"
      :server-items-length="total"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.email`]="{ item }">
        <span>
          {{ item.user ? item.user.email : '' }}
        </span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="cont-actions">
          <v-btn color="primary" @click="goToPath('/payment/detail/' + item.id)" outlined> View </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      headers: [
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Client Account', value: 'client_id', sortable: true },
        { text: 'Transaction Id', value: 'transaction_id', sortable: true },
        { text: 'Total Amount', value: 'total_amount', sortable: true },
        { text: 'Payment Date', value: 'created_at', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items_per_page: [5, 10, 25, 50, 100],
      sort_desc: true,
      options: {},
      search: {
        search: '',
      },
      errors: null,
    }
  },
  watch: {
    options: {
      handler() {
        this.getList()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getList()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/set',
      getHistory: 'payment/getHistory',
    }),
    async getList() {
      this.loading = true
      await this.getHistory(
        new URLSearchParams({
          ...this.options,
          ...this.search,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.items = resp.data.items
            this.total = resp.data.total
          }
        })
        .catch(error => {
          console.log(error)

          this.setSnackbar({
            status: true,
            text: 'Transaction could not be completed.',
            color: 'error',
          })

          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          } else {
            this.errors = null
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(date) {
      return moment(date).fromNow()
    },
    clearSearch() {
      this.search.search = ''
    },
    goToPath(path) {
      this.$router.push({ path })
    },
    goTo(route) {
      this.$router.push({ name: route })
    },
  },
}
</script>

<style lang="css">
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
</style>
