<template>
  <v-dialog v-model="isPlanUpgradeOpen" max-width="650" @click:outside="cancel">
    <v-card class="user-card-detail pa-sm-10 pa-3">
      <v-card-title class="user-edit-title justify-center text-h5"> Add Card </v-card-title>
      <v-card-text class="text-center mt-n2"> Add card for future billing </v-card-text>

      <v-card-text class="mt-5">
        <v-form ref="form" lazy-validation class="multi-col-validation">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-maska="'#### #### #### ####'"
                :rules="[rules.required, rules.cardMax, rules.cardMin, rules.cardValidate]"
                label="Card Number"
                outlined
                dense
                @maska="newCard.card_number_unmask = $event.target.dataset.maskRawValue"
              >
                <template v-slot:append>
                  <v-fade-transition leave-absolute>
                    <v-icon v-if="!cardTypeIcon"> mdi-credit-card </v-icon>
                    <v-img v-else contain height="24px" :src="require(`@/assets/images/logos/${cardTypeIcon}.png`)">
                    </v-img>
                  </v-fade-transition>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-combobox
                v-model="newCard.expired_month"
                outlined
                dense
                :items="months"
                required
                :rules="[rules.required]"
                label="Expiration Month"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="4">
              <v-combobox
                v-model="newCard.expired_year"
                outlined
                dense
                :items="years"
                required
                :rules="[rules.required]"
                label="Expiration Year"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="newCard.cvv"
                v-maska="'###'"
                outlined
                dense
                :rules="[rules.required, rules.cvv]"
                required
                label="Security Code"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newCard.customer_first_name"
                outlined
                dense
                :rules="[rules.required]"
                required
                label="First Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newCard.customer_last_name"
                outlined
                dense
                :rules="[rules.required]"
                required
                label="Last Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="newCard.customer_address"
                outlined
                dense
                :rules="[rules.required]"
                required
                label="Address"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newCard.customer_address_country"
                outlined
                dense
                :rules="[rules.required]"
                required
                label="Country"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newCard.customer_address_city"
                outlined
                dense
                :rules="[rules.required]"
                required
                label="City"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newCard.customer_address_state"
                outlined
                dense
                :rules="[rules.required]"
                required
                label="State"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newCard.customer_zip"
                type="number"
                outlined
                dense
                :rules="[rules.required]"
                required
                label="Zip"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="newCard.customer_email"
                outlined
                dense
                :rules="[rules.required, rules.email]"
                required
                label="Email"
              ></v-text-field>
            </v-col>
            <v-col v-if="error" cols="12">
              <div style="color: #f44336"><strong>Error:</strong> {{ error }}</div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                color="primary"
                class="me-3"
                :loading="loadingTransaction"
                :disabled="loadingTransaction"
                @click="savepayment"
              >
                Submit
              </v-btn>
              <v-btn color="secondary" outlined @click="cancel"> Cancel </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { maska } from 'maska'

export default {
  directives: { maska },
  props: {
    isPlanUpgradeOpen: {
      type: Boolean,
      required: true,
    },

    refresh: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      newCard: {
        card_number_unmask: null,
        expired_year: null,
        expired_month: null,
        cvv: null,
        customer_first_name: null,
        customer_last_name: null,
        customer_address: null,
        customer_address_city: null,
        customer_address_state: null,
        customer_address_country: null,
        customer_zip: null,
        customer_email: null,
      },
      loadingTransaction: false,
      error: null,
      rules: {
        required: value => !!value || 'Required.',
        cardMax: v => {
          const vs = v ? v.replace(/\s/g, '') : v

          return (vs && vs.length <= 18) || 'Max 18 characters'
        },
        cardMin: v => {
          const vs = v ? v.replace(/\s/g, '') : v

          return (vs && vs.length >= 13) || 'Min 13 characters'
        },
        cvv: v => (v && v.length === 3) || '3 characters',
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

          return pattern.test(value) || 'Invalid e-mail.'
        },
        cardValidate: v => {
          const vs = v ? v.replace(/\D/g, '') : v
          const r =
            /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/

          return r.test(vs) || 'Invalid card'
        },
      },
    }
  },
  computed: {
    years() {
      const actualYear = new Date().getFullYear()
      const years = [actualYear]
      for (let index = 1; index < 9; index += 1) {
        years[index] = actualYear + index
      }

      return years
    },
    months() {
      let actualMonth = 1
      if (this.newCard.expired_year === new Date().getFullYear()) {
        actualMonth = new Date().getMonth() + 1
      }
      const months = []
      for (let index = actualMonth; index <= 12; index += 1) {
        if (index <= 9) {
          months.push(`0${index}`)
        } else {
          months.push(index)
        }
      }

      return months
    },
    cardTypeIcon() {
      const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/
      const mastercardRegEx = /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/
      const amexpRegEx = /^(?:3[47][0-9]{13})$/
      const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/
      const jcbRegEx = /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/
      const dinnersRegEx = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/

      if (visaRegEx.test(this.newCard.card_number_unmask)) {
        return 'Visa'
      }
      if (mastercardRegEx.test(this.newCard.card_number_unmask)) {
        return 'MasterCard'
      }
      if (amexpRegEx.test(this.newCard.card_number_unmask)) {
        return 'AmericanExpress'
      }
      if (discovRegEx.test(this.newCard.card_number_unmask)) {
        return 'Discover'
      }
      if (jcbRegEx.test(this.newCard.card_number_unmask)) {
        return 'JCB'
      }
      if (dinnersRegEx.test(this.newCard.card_number_unmask)) {
        return 'DinersClub'
      }

      return null
    },
  },
  methods: {
    ...mapActions({
      save: 'payment/savePaymentProfile',
      setSnackbar: 'snackbar/set',
    }),
    async savepayment() {
      if (this.$refs.form.validate()) {
        this.loadingTransaction = true
        this.error = null
        await this.save(this.newCard)
          .then(resp => {
            if (resp && resp.data) {
              if (resp.data.status === 'ok') {
                this.setSnackbar({
                  status: true,
                  text: 'Card has been added successfully.',
                  color: 'primary',
                })
                this.clear()
                this.refresh()
              }
            }
          })
          .finally(() => {
            this.loadingTransaction = null
          })
          .catch(err => {
            this.error = err.response.data.errors
          })
      }
    },
    cancel() {
      this.clear()
    },
    clear() {
      this.newCard = {
        card_number_unmask: null,
        expired_year: null,
        expired_month: null,
        cvv: null,
        customer_first_name: null,
        customer_last_name: null,
        customer_address: null,
        customer_address_city: null,
        customer_address_state: null,
        customer_address_country: null,
        customer_zip: null,
        customer_email: null,
      }
      this.$refs.form.reset()
      this.$emit('update:is-plan-upgrade-open', false)
    },
  },
}
</script>
<style >
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
