<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="error" outlined class="mb-3 mb-sm-0"> Cancel Subscription </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5"> Do you want to cancel the subscription? </v-card-title>
      <v-card-text>
        <p>
          Once your subscription has been cancelled, the automatic renewal will stop, so you will no longer be able to
          get reports on a monthly basis. Your subscription reports will be active until the end of the remaining days
          in your subscription, once the remaining time has expired they will return to zero.
        </p>
        <v-alert v-if="!!errors" class="mt-4" color="error" text>
          <p class="font-weight-semibold mb-1">Messages</p>
          <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
            {{ error }}
          </p>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="dialog = false"> Close </v-btn>
        <v-btn color="error" @click="cancelSubscription" :disabled="loading">
          <span v-if="loading === false">Cancel Subscription</span>
          <span v-else>
            <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
            <span>Canceling...</span>
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, onBeforeMount, meh, ref, unref, watch } from '@vue/composition-api'
// store
import store from '@/store'

export default {
  props: {
    refresh: Function,
  },
  setup(props) {
    const loading = ref(false)
    const errors = ref(null)
    const dialog = ref(false)

    const cancelSubscription = async () => {
      loading.value = true
      return await store
        .dispatch('subscription/cancel', {})
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              await store.dispatch('snackbar/set', {
                status: true,
                text: resp.data.message,
                color: 'primary',
              })

              errors.value = null
              dialog.value = false
              props.refresh()
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      errors,
      dialog,
      cancelSubscription,
    }
  },
}
</script>
