<template>
  <div class="user-tab-billings-plans">
    <!-- payment cards -->
    <v-card class="mb-7">
      <v-card-title class="card-header">
        <p class="mb-2 mb-sm-0">Payment Methods</p>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="isNewPaymentProfile()">
          <v-icon size="18" class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Add Card</span>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-skeleton-loader v-if="loading" class="mx-auto" type=" article, actions"> </v-skeleton-loader>

        <div v-else class="row-cards">
          <v-card v-for="paymentCard in profiles" :key="paymentCard.paymentID" outlined class="pay-card">
            <v-card-text class="card-content">
              <div class="mb-3 mb-sm-0">
                <v-img
                  contain
                  max-width="5rem"
                  height="25"
                  :src="require(`@/assets/images/logos/${paymentCard.cardType}.png`)"
                ></v-img>
                <div class="text--primary font-weight-medium mt-2">
                  <span class="text-base me-2"
                    >{{ paymentCard.cardOwnerFirstName }} {{ paymentCard.cardOwnerLastName }}</span
                  >
                </div>
                <span>
                  <span>{{ paymentCard.cardInfo }}</span>
                </span>
              </div>

              <div class="text-sm-right">
                <v-btn color="secondary" outlined class="ms-3 mb-5" @click="dialogDialog(paymentCard.paymentID)">
                  Delete
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>

    <!-- Billing Address -->
    <billing-address />

    <!-- edit card details -->
    <user-tab-payment-details-edit :is-plan-upgrade-open.sync="isPlanUpgradeOpen" :card-detail="cardDetail">
    </user-tab-payment-details-edit>
    <new-payment-profile :is-plan-upgrade-open.sync="isNewPaymentProfileOpen" :refresh="getPayments">
    </new-payment-profile>
    <DialogDelete
      :show.sync="show"
      :item="{ idDelete }"
      title="Are you sure to delete this card?"
      description="This process is irreversible, and can no longer be recovered, would you like to continue?"
      :delete="deleteReport"
    >
    </DialogDelete>
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiPlus, mdiSquareEditOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'
//components
import DialogDelete from '@/components/dialog/DialogDelete.vue'
import NewPaymentProfile from './NewPaymentProfile.vue'
import UserTabPaymentDetailsEdit from './PaymentDetailsEdit.vue'
import BillingAddress from './BillingAddress.vue'

export default {
  components: {
    UserTabPaymentDetailsEdit,
    NewPaymentProfile,
    DialogDelete,
    BillingAddress,
  },
  props: {
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      profiles: null,
      idDelete: null,
      show: false,
      loading: false,
      errors: null,
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  mounted() {
    this.getPayments()
    if (!(this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner'))) {
      this.loading = false
      this.$router.push({ name: 'not-authorized' })
    }
  },
  methods: {
    ...mapActions({
      payments: 'payment/getPayments',
      delete: 'payment/deletePayment',
      setSnackbar: 'snackbar/set',
    }),
    async getPayments() {
      this.loading = true
      await this.payments()
        .then(resp => {
          if (resp && resp.data) {
            this.profiles = null
            this.profiles = resp.data.profilesData.profiles
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    dialogDialog(id) {
      this.idDelete = id
      this.show = true
    },
    async deleteReport() {
      await this.delete(this.idDelete)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status === 'ok') {
              this.setSnackbar({
                status: true,
                text: 'Record was successfully deleted.',
                color: 'primary',
              })
            }
          }
          this.idDelete = null
        })
        .finally(() => {
          this.getPayments()
        })
    },
  },

  setup() {
    const currentBillingAddress = {
      companyName: 'Kelly Group',
      billingEmail: 'gertrude@gmail.com',
      taxID: 'TAX-875623',
      vatNumber: 'SDF754K77',
      address: '100 Water Plant Avenue, Building 1303 Wake Island',
      contact: '+1(609) 933-44-22',
      country: 'USA',
      state: 'Capholim',
      zipCode: '403114',
    }
    const isEditAddressDialogVisible = ref(false)
    const cardDetail = ref({})
    const isPlanUpgradeOpen = ref(false)
    const isNewPaymentProfileOpen = ref(false)

    const isNewPaymentProfile = () => {
      if (isNewPaymentProfileOpen.value) {
        isNewPaymentProfileOpen.value = false
      } else {
        isNewPaymentProfileOpen.value = true
      }
    }

    return {
      cardDetail,
      isPlanUpgradeOpen,
      isNewPaymentProfileOpen,
      isEditAddressDialogVisible,
      currentBillingAddress,

      isNewPaymentProfile,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiSquareEditOutline,
      },
    }
  },
}
</script>

<style scoped="css">
.row-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.pay-card {
  width: 100%;
  max-width: 350px;
}

@media (max-width: 768px) {
  .row-cards {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 420px) {
  .card-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}
</style>
