<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- suscriptions -->
        <div class="text-center">
          <h1>Suscriptions </h1>
        </div>
        <div class="plans">
          <v-card
            v-if="loadingServices"
            class="card-plan"
          >
            <v-card-text class="card-content">
              <v-skeleton-loader type="list-item-three-line,article, actions"></v-skeleton-loader>
            </v-card-text>
          </v-card>
          <v-card
            v-for="service in dataServices"
            v-else
            :key="service.id"
            class="card-plan"
          >
            <v-card-text class="card-content">
              <h2 class="card-title">
                {{ service.name }}
              </h2>
              <span class="price">${{ service.price }}</span>
              <div class="features">
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>{{ service.keyword }} Keyword Research</span>
                </div>
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>{{ service.serp }} SERP Reports</span>
                </div>
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>{{ service.semantic }} Semantic Reports</span>
                </div>
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>{{ service.full }} Full Report</span>
                </div>
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>Full Access 24/7</span>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="card-actions">
              <v-btn
                :to="'payment?subscription=' + service.id"
                color="primary"
                outlined
                class="mb-3 mb-sm-0"
              >
                Subscribe
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12">
        <!-- Product -->
        <div class="text-center">
          <h1>Pay as you go </h1>
        </div>
        <div class="plans">
          <v-card
            v-if="loadingServices"
            class="card-plan"
          >
            <v-card-text class="card-content">
              <v-skeleton-loader type="list-item-three-line,article, actions"></v-skeleton-loader>
            </v-card-text>
          </v-card>
          <v-card
            v-for="product in dataProducts"
            v-else
            :key="product.id"
            class="card-plan"
          >
            <v-card-text class="card-content">
              <h2 class="card-title">
                {{ product.name }}
              </h2>
              <span class="price">${{ product.price }}</span>
              <div class="features">
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>{{ product.keyword }} Keyword Research</span>
                </div>
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>{{ product.serp }} SERP Reports</span>
                </div>
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>{{ product.semantic }} Semantic Reports</span>
                </div>
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>{{ product.full }} Full Report</span>
                </div>
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>Full Access 24/7</span>
                </div>
                <div class="item">
                  <v-icon size="22">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span>Never expire</span>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="card-actions">
              <v-btn
                :to="'payment?product=' + product.id"
                color="warning"
                outlined
                class="mb-3 mb-sm-0"
              >
                Buy
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiCheck } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiCheck,
      },
      dataServices: {},
      dataProducts: {},
      loadingServices: false,
    }
  },
  mounted() {
    this.getServices()
  },
  methods: {
    ...mapActions({
      services: 'payment/getServices',
    }),
    async getServices() {
      this.loadingServices = true
      await this.services().then(resp => {
        if (resp && resp.data) {
          this.dataServices = null
          this.dataServices = resp.data.services
          this.dataProducts = resp.data.singlePayment
        }
      }).finally(() => {
        this.loadingServices = false
      })
    },
  },
}
</script>

<style lang="css" scoped>
.text-center {
  text-align: center;
}

.plans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title {
  text-align: center;
  /* text-transform: uppercase; */
}

.card-plan {
  max-width: 300px;
  width: 100%;
  min-height: 350px;
}

.card-content {
  display: flex;
  flex-direction: column;
  min-height: 350px;
}

.card-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price {
  text-align: center;
  font-weight: 500;
  font-size: 60px;
}

.features {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.item {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

@media (max-width: 992px) {
  .plans {
    gap: 20px;
  }
}
</style>
