<template>
  <div id="user-view">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows class="user-tabs">
      <v-tab v-for="tabr in tabs" :key="tabr.title">
        <v-icon size="20" class="me-3">
          {{ tabr.icon }}
        </v-icon>
        <span>{{ tabr.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items id="user-tabs-content" v-model="tab" class="mt-5 pa-1">
      <v-tab-item>
        <subscription />
      </v-tab-item>

      <v-tab-item>
        <billing :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible" />
      </v-tab-item>

      <v-tab-item>
        <plans />
      </v-tab-item>

      <v-tab-item>
        <payment-history />
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
import {
  // mdiAccountOutline,
  // mdiLockOpenOutline,
  // mdiInformationOutline,
  // mdiBookmarkOutline,
  // mdiBellOutline,
  // mdiMathLog,
  // mdiLoginVariant,
  mdiCreditCardOutline,
} from '@mdi/js'
import Subscription from './components/Subscription/Subscription.vue'
import Billing from './components/Billing/Billing.vue'
import Plans from './components/Plans/Plans.vue'
import PaymentHistory from './components/PaymentHistory'

export default {
  components: {
    Billing,
    Subscription,
    Plans,
    PaymentHistory,
  },
  data() {
    return {
      tab: 0,
      tabs: [
        { title: 'Subscription', icon: mdiCreditCardOutline },
        { title: 'Billing', icon: mdiCreditCardOutline },
        { title: 'Plans', icon: mdiCreditCardOutline },
        { title: 'History', icon: mdiCreditCardOutline },
      ],
      isPlanUpgradeDialogVisible: false,
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.tabs.filter((row, index) => {
        if (row.title.toLowerCase() === this.$route.query.tab.toLowerCase()) {
          this.tab = index
        }
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
