<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="">
          <v-card-title> Current plan </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <h4 class="mb-1">
                  <span class="font-weight-medium me-1">Your Current Plan is</span>
                  <span>{{ dataCurrent.name }}</span>
                </h4>
                <p>{{ dataCurrent.description }}</p>

                <h4 class="font-weight-medium mb-1">
                  Active until {{ expireDate }}
                  <!-- Dec 09, 2021  -->
                </h4>
                <p>We will send you a notification when your subscription expires.</p>

                <div class="d-flex align-center">
                  <h4 class="font-weight-medium me-2 mb-1">${{ dataCurrent.price }} Per Month</h4>
                  <!-- <v-chip
                    label
                    color="primary"
                    small
                    class="v-chip-light-bg primary--text font-weight-semibold mb-1"
                  >
                    Popular
                  </v-chip> -->
                </div>
                <!-- <p>Standard plan for small to medium businesses</p> -->
              </v-col>
              <v-col cols="12" md="6">
                <v-alert color="warning" text v-if="alert">
                  <p class="font-weight-semibold mb-1">We need your attention!</p>
                  <p class="text-sm mb-0">
                    Automatic renewal has been cancelled, your subscription will be active until
                    {{ moment(dataExpireDate).format('LL') }}.
                  </p>
                </v-alert>

                <p class="d-flex font-weight-semibold text--primary mt-3 mb-2">
                  <span>Days</span>
                  <v-spacer></v-spacer>
                  <span>{{ dataRemainingDays }} of {{ dataMonthDays }} Days</span>
                </p>
                <v-progress-linear
                  :indeterminate="loadingRemaining"
                  :value="daysPercentage"
                  height="10"
                  rounded
                ></v-progress-linear>
                <!-- <p class="mt-2">
                  Your plan requires update
                </p> -->

                <h4 class="font-weight-medium mt-3 mb-1">Subscription Status</h4>
                <v-chip class="mt-1" :color="active ? 'green' : 'red'" outlined>
                  {{ active ? 'Active' : 'Inactive' }}
                </v-chip>
              </v-col>

              <v-col cols="12" class="d-flex align-center flex-wrap">
                <dialog-upgrade-plan v-if="!loading" :subcription="dataCurrent" />
                <dialog-cancel-subscription v-if="active" :refresh="getSubscriptionInfo" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title> Subscription reports </v-card-title>
          <v-card-text>
            <v-skeleton-loader v-if="loadingRemaining" type="article, paragraph"></v-skeleton-loader>
            <v-list v-else dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ dataRemaining.keyword_service }} / {{ dataCurrent.keyword }} Keyword Research
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ dataRemaining.serp_service }} / {{ dataCurrent.serp }} SERP Reports
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ dataRemaining.semantic_service }} / {{ dataCurrent.semantic }} Semantic Reports
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ dataRemaining.full_service }} / {{ dataCurrent.full }} Full Report
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title> Reports purchased </v-card-title>
          <v-card-text>
            <v-skeleton-loader v-if="loadingRemaining" type="article, paragraph"></v-skeleton-loader>
            <v-list v-else dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ dataRemaining.keyword }} Keyword Research</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ dataRemaining.serp }} SERP Reports</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ dataRemaining.semantic }} Semantic Reports</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ dataRemaining.full }} Full Report</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script >
import moment from 'moment'
import { computed, onBeforeMount, meh, ref, unref, watch } from '@vue/composition-api'
// store
import store from '@/store'
// components
import DialogCancelSubscription from './components/DialogCancelSubscription.vue'
import DialogUpgradePlan from './components/DialogUpgradePlan.vue'

export default {
  name: 'Subscription',
  components: { DialogCancelSubscription, DialogUpgradePlan },
  setup(props) {
    const loading = ref(true)

    const errors = ref(null)

    const dataCurrent = ref({
      name: 'Free',
      description: 'You do not have a plan',
      price: 0,
      keyword: 0,
      serp: 0,
      semantic: 0,
      full: 0,
      level: 0,
    })
    const dataRemaining = ref({
      keyword: 0,
      serp: 0,
      semantic: 0,
      full: 0,
      keyword_service: 0,
      serp_service: 0,
      semantic_service: 0,
      full_service: 0,
    })
    const dataExpireDate = ref(null)
    const dataMonthDays = ref(null)
    const dataRemainingDays = ref(null)
    const loadingRemaining = ref(false)
    const active = ref(false)
    const auto_renew = ref(false)

    const expireDate = computed(() => {
      if (dataExpireDate.value) {
        return new Date(dataExpireDate.value).toDateString()
      }
      return '-'
    })
    const daysPercentage = computed(() => {
      if (dataMonthDays.value) {
        return (dataRemainingDays.value / dataMonthDays.value) * 100
      }
      return 0
    })
    const alert = computed(() => {
      if (!active.value && moment(dataExpireDate.value) > moment()) {
        return true
      }
      return false
    })

    // store methods
    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }
    const getSubscriptionInfo = async () => {
      loading.value = true

      return await store
        .dispatch('subscription/info', {})
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              dataMonthDays.value = resp.data.subscription_info.month_days
              dataRemainingDays.value = resp.data.subscription_info.remaining_days
              dataExpireDate.value = resp.data.subscription_info.expire_date
              dataCurrent.value = resp.data.subscription_info.service
              auto_renew.value = resp.data.subscription_info.auto_renew
              active.value = resp.data.subscription_info.active
              dataRemaining.value = resp.data.remaining_report
            }
          }
        })
        .catch(error => {
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    //cycle of life
    onBeforeMount(async () => {
      await getSubscriptionInfo()
    })

    return {
      loading,
      errors,

      dataCurrent,
      dataRemaining,
      dataExpireDate,
      dataMonthDays,
      dataRemainingDays,
      loadingRemaining,
      active,
      auto_renew,
      alert,

      expireDate,
      daysPercentage,

      setSnackbar,
      getSubscriptionInfo,

      moment,
    }
  },
}
</script>
