<template>
  <div>
    <!-- Billing Address -->
    <v-card class="mb-7">
      <v-card-title>
        <p class="mb-2 mb-sm-0">Billing Address</p>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="isEditAddressDialogVisible = !isEditAddressDialogVisible"> Edit Address </v-btn>
      </v-card-title>

      <v-card-text>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="article, article"> </v-skeleton-loader>

        <v-row v-else>
          <v-col cols="12" lg="6">
            <table class="billing-address-table">
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Address:</p>
                </td>
                <td>
                  <p>{{ address }}</p>
                </td>
              </tr>
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Address 2:</p>
                </td>
                <td>
                  <p>{{ address2 }}</p>
                </td>
              </tr>
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">City:</p>
                </td>
                <td>
                  <p>{{ city }}</p>
                </td>
              </tr>
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">State:</p>
                </td>
                <td>
                  <p>{{ state }}</p>
                </td>
              </tr>
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Zip Code:</p>
                </td>
                <td>
                  <p>{{ zip_code }}</p>
                </td>
              </tr>
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Country:</p>
                </td>
                <td>
                  <p>{{ country }}</p>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" lg="6">
            <table class="billing-address-table">
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Phone Number:</p>
                </td>
                <td>
                  <p>{{ phone_number }}</p>
                </td>
              </tr>
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Billing Email:</p>
                </td>
                <td>
                  <p>{{ email }}</p>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- edit address dialog -->
    <v-dialog v-model="isEditAddressDialogVisible" max-width="650">
      <v-card class="pa-sm-10 pa-3">
        <v-card-title class="justify-center text-h5 px-5"> Edit Address </v-card-title>
        <v-card-text class="text-center mt-n2 px-5"> Add your billing address. </v-card-text>
        <v-card-text class="pt-5">
          <v-form class="multi-col-validation" ref="form">
            <v-row>
              <v-col cols="12" sm="6">
                <v-textarea
                  v-model="dialog_address"
                  label="Address"
                  rows="2"
                  outlined
                  dense
                  :rules="[validators.required]"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-textarea label="Address 2" rows="2" outlined dense v-model="dialog_address2" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="City" outlined dense v-model="dialog_city" :rules="[validators.required]" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="State" outlined dense v-model="dialog_state" :rules="[validators.required]" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Zip Code"
                  outlined
                  dense
                  v-model="dialog_zip_code"
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="dialog_country"
                  :items="countries"
                  label="Select Country"
                  item-text="name"
                  item-value="name"
                  dense
                  outlined
                  :rules="[validators.required]"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-mask="'(###) ###-####'"
                  label="Phone Number"
                  outlined
                  dense
                  v-model="dialog_phone_number"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Email"
                  outlined
                  type="email"
                  dense
                  v-model="dialog_email"
                  :rules="[validators.emailValidator]"
                />
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn color="primary" class="me-3" @click="submit"> Submit </v-btn>
                <v-btn outlined color="secondary" @click="isEditAddressDialogVisible = false"> Cancel </v-btn>
              </v-col>
              <v-col>
                <v-alert v-if="!!dialogErrors" class="mt-4" color="error" text>
                  <p class="font-weight-semibold mb-1">Messages</p>
                  <p v-for="(error, i) in dialogErrors" :key="i" class="text-sm mb-0">
                    {{ error }}
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiPlus, mdiSquareEditOutline } from '@mdi/js'
import { computed, onBeforeMount, meh, ref, unref, watch } from '@vue/composition-api'
import countries from '@/const/countries'
import { required, emailValidator, urlValidator, integerValidator } from '@core/utils/validation'
import store from '@/store'

export default {
  setup(props) {
    const loading = ref(false)
    const updating = ref(false)

    //form
    const form = ref(null)

    //errors
    const errors = ref(null)
    const dialogErrors = ref(null)

    // card information
    const id = ref('')
    const address = ref('')
    const address2 = ref('')
    const city = ref('')
    const state = ref('')
    const zip_code = ref('')
    const country = ref('')
    const phone_number = ref('')
    const email = ref('')

    // dialog fields
    const dialog_address = ref('')
    const dialog_address2 = ref('')
    const dialog_city = ref('')
    const dialog_state = ref('')
    const dialog_zip_code = ref('')
    const dialog_country = ref('')
    const dialog_phone_number = ref('')
    const dialog_email = ref('')

    const isEditAddressDialogVisible = ref(false)

    // store methods
    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }
    const getBillingAddress = async () => {
      loading.value = true

      return await store
        .dispatch('billing_address/get', '')
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.data) {
              id.value = resp.data.data.id
              address.value = resp.data.data.address
              address2.value = resp.data.data.address2
              city.value = resp.data.data.city
              state.value = resp.data.data.state
              zip_code.value = resp.data.data.zip_code
              country.value = resp.data.data.country
              phone_number.value = resp.data.data.phone_number
              email.value = resp.data.data.email

              dialog_address.value = resp.data.data.address
              dialog_address2.value = resp.data.data.address2
              dialog_city.value = resp.data.data.city
              dialog_state.value = resp.data.data.state
              dialog_zip_code.value = resp.data.data.zip_code
              dialog_country.value = resp.data.data.country
              dialog_phone_number.value = resp.data.data.phone_number
              dialog_email.value = resp.data.data.email
            }
          }
        })
        .catch(error => {
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
        })
        .finally(() => {
          loading.value = false
        })
    }
    const submit = async () => {
      const check = form.value.validate()
      if (!check) {
        return
      }

      let data = {
        id: id.value,
        address: dialog_address.value,
        address2: dialog_address2.value,
        city: dialog_city.value,
        state: dialog_state.value,
        zip_code: dialog_zip_code.value,
        country: dialog_country.value,
        phone_number: dialog_phone_number.value,
        email: dialog_email.value,
      }

      loading.value = true
      return await store
        .dispatch('billing_address/update', data)
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.data) {
              address.value = resp.data.data.address
              address2.value = resp.data.data.address2
              city.value = resp.data.data.city
              state.value = resp.data.data.state
              zip_code.value = resp.data.data.zip_code
              country.value = resp.data.data.country
              phone_number.value = resp.data.data.phone_number
              email.value = resp.data.data.email

              dialog_address.value = resp.data.data.address
              dialog_address2.value = resp.data.data.address2
              dialog_city.value = resp.data.data.city
              dialog_state.value = resp.data.data.state
              dialog_zip_code.value = resp.data.data.zip_code
              dialog_country.value = resp.data.data.country
              dialog_phone_number.value = resp.data.data.phone_number
              dialog_email.value = resp.data.data.email

              await store.dispatch('snackbar/set', {
                status: true,
                text: resp.data.message,
                color: 'primary',
              })

              isEditAddressDialogVisible.value = false
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            dialogErrors.value = error.response.data.errors
          } else {
            dialogErrors.value = null
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    //cycle of life
    onBeforeMount(async () => {
      await getBillingAddress()
    })

    return {
      loading,
      updating,
      form,
      errors,
      dialogErrors,

      id,
      address,
      address2,
      city,
      state,
      zip_code,
      country,
      phone_number,
      email,

      dialog_address,
      dialog_address2,
      dialog_city,
      dialog_state,
      dialog_zip_code,
      dialog_country,
      dialog_phone_number,
      dialog_email,

      isEditAddressDialogVisible,

      countries,

      validators: {
        required,
        emailValidator,
        urlValidator,
        integerValidator,
      },

      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiSquareEditOutline,
      },

      //actions
      submit,
    }
  },
}
</script>
