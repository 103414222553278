<template>
  <v-dialog v-model="dialog" persistent :max-width="subcription.level < 1 ? '1200' : '720'">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="subcription.level != 3" v-bind="attrs" v-on="on" color="primary" class="me-3 mb-3 mb-sm-0">
        Upgrade Plan
      </v-btn>
      <v-btn v-else color="info" class="me-3 mb-3 mb-sm-0"> Awesome! You've the best plan </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <!-- suscriptions -->
            <div class="text-center">
              <h1 class="mb-3">Suscriptions</h1>
            </div>
            <div class="plans">
              <v-card v-if="loading" class="card-plan">
                <v-card-text class="card-content">
                  <v-skeleton-loader type="list-item-three-line,article, actions"></v-skeleton-loader>
                </v-card-text>
              </v-card>
              <v-card v-for="service in plans" v-else :key="service.id" class="card-plan">
                <v-card-text class="card-content">
                  <h2 class="card-title">
                    {{ service.name }}
                  </h2>
                  <span class="price">${{ service.price }}</span>
                  <div class="features">
                    <div class="item">
                      <v-icon size="22">
                        {{ icons.mdiCheck }}
                      </v-icon>
                      <span>{{ service.keyword }} Keyword Research</span>
                    </div>
                    <div class="item">
                      <v-icon size="22">
                        {{ icons.mdiCheck }}
                      </v-icon>
                      <span>{{ service.serp }} SERP Reports</span>
                    </div>
                    <div class="item">
                      <v-icon size="22">
                        {{ icons.mdiCheck }}
                      </v-icon>
                      <span>{{ service.semantic }} Semantic Reports</span>
                    </div>
                    <div class="item">
                      <v-icon size="22">
                        {{ icons.mdiCheck }}
                      </v-icon>
                      <span>{{ service.full }} Full Report</span>
                    </div>
                    <div class="item">
                      <v-icon size="22">
                        {{ icons.mdiCheck }}
                      </v-icon>
                      <span>Full Access 24/7</span>
                    </div>
                  </div>

                  <v-alert v-if="!!errors" class="mt-4" color="error" text>
                    <p class="font-weight-semibold mb-1">Messages</p>
                    <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
                      {{ error }}
                    </p>
                  </v-alert>
                </v-card-text>
                <v-card-actions class="card-actions">
                  <v-btn :to="'payment?subscription=' + service.id" color="primary" outlined class="mb-3 mb-sm-0">
                    Subscribe
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, onBeforeMount, meh, ref, unref, watch } from '@vue/composition-api'
// store
import store from '@/store'
//icons
import { mdiCheck } from '@mdi/js'

export default {
  props: {
    subcription: Object,
  },
  setup(props) {
    const loading = ref(false)
    const errors = ref(null)
    const dialog = ref(false)

    const plans = ref(null)

    // store methods
    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }
    const getSubscriptionLeves = async () => {
      loading.value = true

      const data = new URLSearchParams({
        current_level: props.subcription.level,
      }).toString()

      return await store
        .dispatch('subscription/levels', data)
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              plans.value = resp.data.plans
            }
          }
        })
        .catch(error => {
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    //cycle of life
    onBeforeMount(async () => {
      await getSubscriptionLeves()
    })

    return {
      loading,
      errors,
      dialog,

      plans,

      setSnackbar,
      getSubscriptionLeves,

      icons: {
        mdiCheck,
      },
    }
  },
}
</script>

<style lang="css" scoped>
.text-center {
  text-align: center;
}

.plans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title {
  text-align: center;
  /* text-transform: uppercase; */
}

.card-plan {
  max-width: 300px;
  width: 100%;
  min-height: 350px;
}

.card-content {
  display: flex;
  flex-direction: column;
  min-height: 350px;
}

.card-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price {
  text-align: center;
  font-weight: 500;
  font-size: 60px;
}

.features {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.item {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

@media (max-width: 992px) {
  .plans {
    gap: 20px;
  }
}
</style>
